import Vue from 'vue'
import VueRouter from 'vue-router'
import PrakerjaRouter from './prakerjaRouter'
import SertifikatRouter from './sertifikatRouter'
// import PortofolioRouter from './portofolioRouter'
import PortofolioRouter from './portofolioV2Router'
import LembagaRouter from './lembagaRouter'
import axios from 'axios'
import store from '../store'
import PaymentRouter from './paymentRouter'
import ReceiptRouter from './receiptRouter'
import docsRouter from './docsRouter'
import refleksiRouter from './refleksiRouter'
import yearLevel from './yearLevel'
import addOnDiscoveryRouter from './addOnDiscoveryRouter'
import authentication from './authentication'
import educationPaymentRouter from './educationPayment'
import livingV2 from './livingV2'
import searchResultRouter from './searchResult'
import parentGuideRouter from './parentGuide'
import billRouter from './bill'
import learningPackageV2 from './learningPackageV2'
import paymentSchemeRouter from './paymentScheme'
import ProgramActivityAPI from '@/api/program-activity'
import { validateActivityAccess } from '@/functions/activityValidate'
import { isMobile } from 'mobile-device-detect'
import appUrl from '@/constants/appUrl.js'
import { WEB_TITLE_DEFAULT, WEB_TITLE_PRAKERJA, META_DESCRIPTION_DEFAULT, META_DESCRIPTION_PRAKERJA, META_KEYWORDS_DEFAULT, META_KEYWORDS_PRAKERJA } from '@/constants/metatags'

Vue.use(VueRouter)

const isPrakerja = location.hostname.toLowerCase().includes('prakerja')
const blackListReferral = [
  '/bantuan',
  '/programmu',
  '/kelasku',
  '/tanyamu',
  '/profile',
  '/portofolio/profil',
  '/portofolio/portofolio',
  '/portofolio/hasil-karya',
  '/transkrip',
  '/rapor-murid',
  '/notifikasi',
  '/tukar-voucher',
  '/daftar-transaksi',
  '/mitra-kolaborasi',
  '/profil-sekolahmu',
  '/dewan-sekolahmu',
  '/syarat-ketentuan',
  '/kebijakan-privasi',
  '/404'
]

const routes = [
  ...searchResultRouter,
  ...livingV2,
  ...SertifikatRouter,
  ...PrakerjaRouter,
  ...PaymentRouter,
  ...PortofolioRouter,
  ...LembagaRouter,
  ...ReceiptRouter,
  ...docsRouter,
  ...refleksiRouter,
  ...yearLevel,
  ...addOnDiscoveryRouter,
  ...authentication,
  ...educationPaymentRouter,
  ...parentGuideRouter,
  ...billRouter,
  ...paymentSchemeRouter,
  ...learningPackageV2,
  {
    path: '/',
    name: 'Home',
    meta: {
      title: isPrakerja ? WEB_TITLE_PRAKERJA : WEB_TITLE_DEFAULT,
      metaTags: [
        {
          name: 'description',
          content: isPrakerja ? META_DESCRIPTION_PRAKERJA : META_DESCRIPTION_DEFAULT
        },
        {
          name: 'keywords',
          content: isPrakerja ? META_KEYWORDS_PRAKERJA : META_KEYWORDS_DEFAULT
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: 'language',
          content: 'id'
        },
        {
          name: 'revisit-after',
          content: '3 days'
        },
        {
          'http-equiv': 'content-type',
          content: 'text/html; charset=utf-8'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/profile-v1',
    name: 'profile',
    component: () => import(/* webpacklChunkName: 'profile' */ '../views/Profile.vue'),
    beforeEnter (to, from, next) {
      if (!window.$cookies.get('access-token')) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/profile',
    name: 'profileV2',
    meta: {
      module: 'profile',
      layout: (isMobile ? 'blank' : 'default')
    },
    component: () => import(/* webpacklChunkName: 'profile' */ '../views/ProfileV3.vue'),
    beforeEnter (to, from, next) {
      if (isMobile && to.query['update-password']) {
        window.location.replace(`sekolahmu://update-password`)
      }
      if (window.$cookies.get('access-token')) {
        next()
      } else if (to.query['update-password']) {
        next('/login?update-password=true')
      } else {
        next('/404')
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'Reset Password',
    meta: {
      module: 'profile',
      layout: (isMobile ? 'blank' : 'default')
    },
    component: () => import(/* webpacklChunkName: 'reset-password' */ '../views/ResetPasswordV2.vue'),
    beforeEnter (to, from, next) {
      if (window.$cookies.get('access-token')) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/tanyamu',
    name: 'tanyamu',
    props: true,
    meta: {
      layout: 'no-footer',
      isLoginNeeded: true
    },
    component: () => import(/* webpacklChunkName: 'tanyamu' */ '../views/tanyamu/Tanyamu.vue')
  },
  {
    path: '/ikutprogram/:id',
    name: 'detail-program',
    component: () => import('../views/DetailProgram.vue')
  },
  {
    path: '/programs',
    name: 'allprograms',
    component: () => import('../views/AllPrograms.vue')
  },
  {
    path: '/aktivitas/:id',
    name: 'activity-program',
    meta: {
      layout: 'activity-v2',
      isLoginNeeded: true
    },
    component: () => import('../views/ActivityV2.vue'),
    async beforeEnter (to, from, next) {
      Vue.nextTick(async function () {
        const apiResult = await ProgramActivityAPI.validateActivity({
          data: {
            type_access: 'activity',
            user_id: store.state.storage.userId,
            slug: to.params.id
          },
          Authorization: store.state.storage.accessToken
        })
        const apiData = apiResult.data.data ? apiResult.data.data : apiResult.data
        const validateResult = validateActivityAccess(apiData)
        if (apiData.platform === 'kariermu') {
          window.open(`${appUrl.kariermuUrl()}/aktivitas/${apiData.activity_slug}`, '_self')
        } else if (validateResult.toNotFound) {
          next('/404')
        } else if (validateResult.toProgramDetail) {
          next(`/program/${apiData.program_slug}`)
        } else if (validateResult.toKelasku) {
          next('/kelasku')
        } else {
          store.commit('activityV2/SET_ACTIVITY_VALIDATE', apiData)
          next()
        }
      })
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report/ProgramReport.vue')
  },
  {
    path: '/transkrip',
    name: 'Transkrip',
    meta: {
      isLoginNeeded: true
    },
    redirect: () => {
      return { name: 'transcript-list-v2' }
    }
  },
  {
    path: '/rapor-murid',
    name: 'transcript-list-v2',
    meta: {
      isLoginNeeded: true,
      module: 'rapor-murid'
    },
    component: () => import('../views/programmu/TranscriptV2.vue')
  },
  {
    path: '/addon',
    name: 'Addon',
    component: () => import('../views/report/AddonReport.vue')
  },
  // temporary redirecting old url /programmu
  {
    path: '/programmu',
    redirect: '/kelasku'
  },
  {
    path: '/kelasku',
    name: 'Kelasku',
    meta: {
      isLoginNeeded: true,
      module: 'kelasku'
    },
    component: () => import(/* webpackChunkName: 'list-programu' */ '../views/programmu/Programmu.vue')
  },
  {
    path: '/jadwal',
    name: 'Jadwal',
    meta: {
      isLoginNeeded: true
    },
    component: () => import(/* webpackChunkName: 'list-programu' */ '../views/schedules')
  },
  {
    path: '/katalog-program/:katalog',
    name: 'Katalog Detail',
    component: () => import(/* webpackChunkName: 'katalog-detail' */ '../views/katalog/KatalogDetail.vue')
  },
  {
    path: '/notifikasi',
    name: 'Notifikasi',
    meta: {
      isLoginNeeded: true
    },
    component: () => import(/* webpackChunkName: 'notifikasi' */ '../views/notification/Notification.vue')
  },
  // temporary route for revamp program detail
  {
    path: '/pengumuman/:slug',
    name: 'Pengumuman',
    component: () => import(/* webpackChunkName: 'pengumuman' */ '../views/announcement/AnnouncementDetail.vue')
  },
  // temporary route for revamp program detail
  {
    path: '/program/:id',
    name: 'ProgramDetail',
    component: () => import(/* webpackChunkName: "DetailProgram" */ '../views/DetailProgramV2.vue')
  },
  {
    path: '/program/v1/:id',
    name: 'ProgramDetailV1',
    component: () => import(/* webpackChunkName: "DetailProgram" */ '../views/DetailProgram.vue')
  },
  {
    path: '/review/:id',
    name: 'ReviewProgram',
    component: () => import('../views/program/ReviewProgram.vue'),
    beforeEnter (to, from, next) {
      if (from.name === 'activity-program') {
        localStorage.setItem('prev-route', from.params.id)
      }
      next()
    }
  },
  {
    path: '/program',
    name: 'Program',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/search/kelas', query: to.query }
    },
    component: () => import(/* webpackChunkName: 'list-program' */ '../views/programmu/ListProgramV2.vue')
  },
  {
    path: '/pencarian',
    name: 'Pencarian',
    component: () => import(/* webpackChunkName: 'list-program' */ '../views/programmu/ListProgram.vue')
  },
  {
    path: '/jenjang/:level/:year',
    name: 'program-by-year-level',
    component: () => import(/* webpackChunkName: 'list-program' */ '../views/program/ProgramByYearLevel.vue')
  },
  {
    path: '/jenjang/:level',
    name: 'program-by-level',
    component: () => import(/* webpackChunkName: 'list-program' */ '../views/program/ProgramByYearLevelV2.vue'),
    beforeEnter (to, from, next) {
      if (to.params.level === 'paud') {
        next('/paud?utm_source=sekolahmu&utm_medium=redirection-link')
      } else if (to.params.level === 'sd') {
        next('/sd?utm_source=sekolahmu&utm_medium=redirection-link')
      } else if (to.params.level === 'smp') {
        next('/smp?utm_source=sekolahmu&utm_medium=redirection-link')
      } else if (to.params.level === 'sma') {
        next('/sma-dan-smk?utm_source=sekolahmu&utm_medium=redirection-link')
      } else {
        next()
      }
    }
  },
  {
    path: '/program-pilihan',
    name: 'Semua-Program-Pilihan',
    component: () => import(/* webpackChunkName: 'all-program' */ '../views/program/AllProgramPilihan.vue')
  },
  {
    path: '/program-pilihan/:slug',
    name: 'Program-Pilihan',
    component: () => import(/* webpackChunkName: 'list-program' */ '../views/program/ProgramPilihan.vue')
  },
  {
    path: '/ikutprogram',
    name: 'Ikut Program',
    meta: {
      title: 'Ikut Program'
    },
    component: () => import(/* webpackChunkName: 'ikut-program' */ '../views/IkutProgram.vue')
  },
  {
    path: '/daftar',
    name: 'daftar',
    meta: {
      layout: 'blank'
    },
    component: () => import('../views/Daftar.vue'),
    beforeEnter: (to, from, next) => {
      next()
    }
  },
  {
    path: '/utbk',
    name: 'Detail UTBK',
    meta: {
      title: 'Persiapan UTBK 2022 - Saintek dan Soshum | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Persiapkan diri menuju UTBK 2022. Akses kumpulan soal tanpa batas, try-out bulanan, hingga konsultasi dengan psikolog pendidikan. Perjalanan menuju kampus impian dimulai dari sekarang!'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'ikut-program' */ '../views/utbk/PageDetailUTBK.vue')
  },
  {
    path: '/kilas-balik-2021',
    name: 'Kilas Balik 2021',
    meta: {
      title: 'Kilas Balik 2021 | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Tahun yang berkesan bersama Sekolah.mu di 2021. Di kilas balik, kamu bisa melihat perkembangan dan dampak yang dihasilkan Sekolah.mu pada dunia pendidikan.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'general-report' */ '../views/flashbackSekolahmu/GeneralReport.vue')
  },
  {
    path: '/redirect-fun-report',
    name: 'Fen Report Deeplink',
    meta: {
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'general-report' */ '../views/flashbackSekolahmu/AppsDeeplinkRedirectionPage.vue')
  },
  {
    path: '/panduan-prakerja',
    name: 'panduan-prakerja',
    component: () => import('../views/PanduanPrakerja.vue')
  },
  {
    path: '/zoom-meeting/android/:roomId/:password/:role/:name/:apiKey',
    name: 'zoom-meeting-android',
    component: () => import('../views/ZoomMeetingAndroid.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/zoom-meeting/:roomId/:password/:role/:name/:apiKey',
    name: 'zoom-meeting',
    component: () => import('../views/ZoomMeeting.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/zoom-leave/',
    name: 'zoom-leave',
    component: () => import('../views/ZoomLeavePage.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/cloudx-meeting/:roomName',
    name: 'cloudx-meeting',
    component: () => import('../views/CloudXStreaming.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/404',
    name: 'not-found-error',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: '404 | Sekolah.mu',
      metaTags: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },
  {
    path: '/:title',
    name: 'redirect',
    component: () => import('../views/Redirect.vue'),
    beforeEnter: async (to, from, next) => {
      if (to.path === '/kariermu') {
        next('/ikutprogram?id=kariermu')
      } else if (to.path === '/kelasmu') {
        next('/ikutprogram?id=kelasmu')
      } else if (to.path === '/ujianmu') {
        next('/ikutprogram?id=ujianmu')
      } else if (to.path === '/prakerja') {
        next('/ikutprogram?id=prakerja')
      } else {
        try {
          const { data } = await axios.get('https://sekolahmu-config.s3-ap-southeast-1.amazonaws.com/web/landing-page-mitra/data-landing-page.json')
          const availableProjects = data.filter(project => project.route === to.path)
          if (availableProjects.length && to.path !== from.path) {
            next({
              name: 'landing-page-project',
              params: {
                project: availableProjects[0].route.slice(1),
                ...availableProjects[0]
              },
              query: to.query
            })
          } else {
            next('/404')
          }
        } catch (error) {
          next('/404')
        }
      }
    }
  },
  {
    path: '/:project',
    name: 'landing-page-project',
    component: () => import('../views/project/CustomizableProject.vue')
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      next('/404')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

router.beforeEach((to, from, next) => {
  // remove google prompt on route change
  if (window.google?.accounts) {
    window.google.accounts.id.cancel()
  }
  const currentCanonicalTag = document.querySelector("[rel='canonical']")

  currentCanonicalTag && currentCanonicalTag.remove()

  const link = document.createElement('link')
  link.rel = 'canonical'

  link.href = `https://www.sekolah.mu${to.path}`
  document.head.appendChild(link)

  if (to.path !== '/404' && document.querySelector("[content='noindex']")) {
    document.querySelector("[content='noindex']").remove()
  }

  if (to.query && to.query.verification && to.path !== '/forgot-password') {
    store.commit('SET_VERIFICATION', to.query)
  }

  if (to.meta.title) {
    document.title = `${to.meta.title} - Sekolah.mu`
  } else {
    document.title = `Sekolah.mu`
  }

  // remove meta
  if (from.meta.metaTags) {
    from.meta.metaTags.forEach(tag => {
      for (const property in tag) {
        if (property === 'content') {
          const meta = document.querySelectorAll(`[${property}='${tag[property]}']`)
          if (meta.length > 0) {
            meta[meta.length - 1].remove()
          }
        }
      }
    })
  }

  // tracker hotjar
  if (store.state.activeEnv === 'production' &&
    store.state.user.isLogin &&
    store.state.user.userInfo.id &&
    Boolean(window.hj)) {
    const userInfo = store.state.user.userInfo
    const userId = userInfo.id || null
    window.hj('identify', userId, {
      user_profession: userInfo.profession_name,
      is_prakerja_user: Boolean(userInfo.prakerja_id),
      registration_date: String(new Date(userInfo.join_date)),
      is_email_verified: userInfo.is_email_verified
    })
  }

  // check if page need to login before access
  if (to.meta.isLoginNeeded && !window.$cookies.get('access-token')) {
    next({ path: '/', query: { 'redirect-path': to.path, 'redirect-name': to.name } })
  }

  // guard routing if user already loggedin
  if (to.meta.isRouteWithoutLogin && window.$cookies.get('access-token')) {
    const query = to.query

    const isContainFormId = to.query.form_id
    if (isContainFormId) {
      next()
      return
    }
    next({ path: '/', query })
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    // DEFINE REFERAL
    if (from.query.ref && !to.query.ref && !blackListReferral.includes(to.path) && (from.path !== '/checkout')) {
      let path = to.path
      let query = to.query
      query.ref = from.query.ref
      return next({ path, query })
    }
    return next()
  }
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    // tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  const retainQueryRef = from.query.ref && !to.query.ref && !blackListReferral.includes(to.path) && (from.path !== '/checkout')
  const retainQueryFormId = from.query.form_id && !to.query.form_id && to.meta.retainFormId

  if (
    retainQueryRef ||
    retainQueryFormId
  ) {
    let path = to.path
    let query = to.query

    if (retainQueryRef) {
      query.ref = from.query.ref
    }

    if (retainQueryFormId) {
      query.form_id = from.query.form_id
    }

    next({ path, query })
  }

  next()
})

export default router
